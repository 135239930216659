<template>
    <div class="flex items-center justify-center h-screen">
        <div class="bg-white flex flex-col justify-center items-center">
            <!-- linkedin logo -->
            <div class="flex w-96">
                <img class="logo h-20" src="../../assets/mainlogo.jpg" />
            </div>

            <!-- Layout  -->
            <div class="bg-white w-96 shadow-lg rounded p-5">
                <h1 class="text-3xl font-medium">Sign In</h1>

                <form class="space-y-5 mt-5" @submit.prevent="login">
                    <input
                        v-model="form.email"
                        @keydown="isError = false"
                        id="email"
                        type="email"
                        class="w-full h-12 border border-gray-200 rounded px-3"
                        placeholder="E-mail"
                        autocomplete="false"
                    />
                    <div
                        class="w-full flex items-center border border-gray-200 rounded px-3"
                    >
                        <input
                            v-model="form.password"
                            @keydown="isError = false"
                            type="password"
                            class="w-11/12 h-12"
                            placeholder="Password"
                        />
                        <span class="text-gray-600 rounded-full"
                            ><i class="bx bxs-show text-xl"></i
                        ></span>
                    </div>

                    <div
                        class="bg-red-100 border-red-200 border-2 rounded-md p-2 text-red-500"
                        v-if="isError"
                    >
                        <span>{{ message }}</span>
                    </div>

                    <div class="">
                        <a
                            href="#!"
                            class="font-medium text-sm text-gray-500 rounded-full p-2"
                            >Forgot Your Password?</a
                        >
                    </div>

                    <button
                        :disabled="isLoading"
                        type="submit"
                        :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                        class="text-center w-full bg-blue-600 rounded-full text-white py-3 font-medium"
                    >
                        <span v-if="!isLoading">Sign in</span>
                        <span v-else>Loading...</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Form from 'vform';
import { mapGetters } from 'vuex';

export default {
    layout: 'login',
    middleware: 'guest',
    components: {},
    data: () => ({
        form: new Form({
            email: '',
            password: '',
        }),
        isLoading: false,
        isError: false,
        message: '',
    }),

    computed: mapGetters({
        user: 'auth/user',
    }),

    methods: {
        async login() {
            this.isLoading = true;

            try {
                const { data } = await this.form.post('/auth/login');
                this.isLoading = false;

                localStorage.token = data.access_token;

                this.$store.dispatch('auth/saveToken', {
                    token: data.access_token,
                    remember: data.remember,
                });
                await this.$store.dispatch('auth/fetchUser');

                if (this.user.data.role === 'teller') {
                    this.$router.push({ path: `/myprofile` });
                } else {
                    this.$router.push({ path: '/dashboard' });
                }
            } catch (error) {
                this.isLoading = false;

                if (error?.response?.status == 401) {
                    this.isError = true;
                    this.message = 'Incorrent username or password';
                }
            }
        },
    },
};
</script>
